import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="druid-spells"></a><h3>DRUID SPELLS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a id="zero-level-druid-spells"></a><a id="orisons"></a><h5>0-LEVEL DRUID SPELLS (ORISONS)</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-water">Create Water</a>:</td>
          <td> Creates 2 gallons/level of pure water.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-minor-wounds">Cure Minor
Wounds</a>:</td>
          <td> Cures 1 point of damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-magic">Detect Magic</a>:</td>
          <td> Detects spells and magic items within 60 ft.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-poison">Detect Poison</a>:</td>
          <td> Detects poison in one creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flare">Flare</a>:</td>
          <td> Dazzles one creature (&ndash;1 penalty on attack
rolls).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#guidance">Guidance</a>:</td>
          <td> +1 on one attack roll, saving throw, or skill check.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#know-direction">Know Direction</a>:</td>
          <td> You discern north.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#light">Light</a>:</td>
          <td> Object shines like a torch.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mending">Mending</a>:</td>
          <td> Makes minor repairs on an object.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#purify-food-and-drink">Purify
Food and Drink</a>:</td>
          <td> Purifies 1 cu. ft./level of food or water.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#read-magic">Read Magic</a>:</td>
          <td> Read scrolls and spellbooks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resistance">Resistance</a>:</td>
          <td> Subject gains +1 bonus on saving throws.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#virtue">Virtue</a>:</td>
          <td> Subject gains 1 temporary hp.</td>
        </tr>
      </tbody>
    </table>
    <a id="first-level-druid-spells"></a><h5>1ST-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-animals">Calm Animals</a>:</td>
          <td> Calms (2d4 + level) HD of animals.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-animal">Charm Animal</a>:</td>
          <td> Makes one animal your friend.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-animals-or-plants">Detect
Animals or Plants</a>:</td>
          <td> Detects kinds of animals or plants.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-snares-and-pits">Detect
Snares and Pits</a>:</td>
          <td> Reveals natural or primitive traps.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#entangle">Entangle</a>:</td>
          <td> Plants entangle everyone in 40-ft.-radius.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#faerie-fire">Faerie Fire</a>:</td>
          <td> Outlines subjects with light, canceling blur,
concealment, and the like.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#goodberry">Goodberry</a>:</td>
          <td> 2d4 berries each cure 1 hp (max 8 hp/24 hours).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hide-from-animals">Hide from
Animals</a>:</td>
          <td> Animals can&rsquo;t perceive one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#jump">Jump</a>:</td>
          <td> Subject gets bonus on Jump checks.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#longstrider">Longstrider</a>:</td>
          <td> Your speed increases by 10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-fang">Magic Fang</a>:</td>
          <td> One natural weapon of subject creature gets +1 on
attack and damage rolls.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-stone">Magic Stone</a>:</td>
          <td> Three stones gain +1 on attack rolls, deal 1d6+1
damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>:</td>
          <td> Fog surrounds you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#pass-without-trace">Pass
without Trace</a>:</td>
          <td> One subject/level leaves no tracks.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#produce-flame">Produce Flame</a>:</td>
          <td> 1d6 damage +1/level, touch or thrown.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shillelagh">Shillelagh</a>:</td>
          <td> Cudgel or quarterstaff becomes +1 weapon (1d10 damage)
for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-animals">Speak with
Animals</a>:</td>
          <td> You can communicate with animals.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-i">Summon Nature&rsquo;s Ally I</a>:</td>
          <td className="last-row"> Calls creature to fight.</td>
        </tr>
      </tbody>
    </table>
    <a id="second-level-druid-spells"></a><h5>2ND-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-messenger">Animal
Messenger</a>:</td>
          <td> Sends a Tiny animal to a specific place.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-trance">Animal Trance</a>:</td>
          <td> Fascinates 2d6 HD of animals.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#barkskin">Barkskin</a>:</td>
          <td> Grants +2 (or higher) enhancement to natural armor.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bears-endurance">Bear&rsquo;s
Endurance</a>:</td>
          <td> Subject gains +4 to Con for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bulls-strength">Bull&rsquo;s
Strength</a>:</td>
          <td> Subject gains +4 to Str for 1 min./level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cats-grace">Cat&rsquo;s Grace</a>:</td>
          <td> Subject gains +4 to Dex for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#chill-metal">Chill Metal</a>:</td>
          <td> Cold metal damages those who touch it.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#delay-poison">Delay Poison</a>:</td>
          <td> Stops poison from harming subject for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-trap">Fire Trap</a><sup> M</sup>:</td>
          <td> Opened object deals 1d4 +1/level damage.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-blade">Flame Blade</a>:</td>
          <td> Touch attack deals 1d8 +1/two levels damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flaming-sphere">Flaming Sphere</a>:</td>
          <td> Creates rolling ball of fire, 2d6 damage, lasts 1
round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fog-cloud">Fog Cloud</a>:</td>
          <td> Fog obscures vision.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gust-of-wind">Gust of Wind</a>:</td>
          <td> Blows away or knocks down smaller creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heat-metal">Heat Metal</a>:</td>
          <td> Make metal so hot it damages those who touch it.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-animal">Hold Animal</a>:</td>
          <td> Paralyzes one animal for 1 round/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#owls-wisdom">Owl&rsquo;s Wisdom</a>:</td>
          <td> Subject gains +4 to Wis for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reduce-animal">Reduce Animal</a>:</td>
          <td> Shrinks one willing animal.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>:</td>
          <td> Ignores 10 (or more) points of damage/attack from
specified energy type.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-restoration">Restoration,
Lesser</a>:</td>
          <td> Dispels magical ability penalty or repairs 1d4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#ability-damaged">ability damage</a>.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#soften-earth-and-stone">Soften
Earth and Stone</a>:</td>
          <td> Turns stone to clay or dirt to sand or mud.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spider-climb">Spider Climb</a>:</td>
          <td> Grants ability to walk on walls and ceilings.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-ii">Summon
Nature&rsquo;s Ally II</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-swarm">Summon Swarm</a>:</td>
          <td> Summons swarm of bats, rats, or spiders.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tree-shape">Tree Shape</a>:</td>
          <td> You look exactly like a tree for 1 hour/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#warp-wood">Warp Wood</a>:</td>
          <td> Bends wood (shaft, handle, door, plank).</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wood-shape">Wood
Shape</a>:</td>
          <td className="last-row"> Rearranges wooden objects to suit you.</td>
        </tr>
      </tbody>
    </table>
    <a id="third-level-druid-spells"></a><h5>3RD-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning">Call Lightning</a>:</td>
          <td> Calls down lightning bolts (3d6 per bolt) from sky.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contagion">Contagion</a>:</td>
          <td> Infects subject with chosen disease.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#daylight">Daylight</a>:</td>
          <td> 60-ft. radius of bright light.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#diminish-plants">Diminish
Plants</a>:</td>
          <td> Reduces size or blights growth of normal plants.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-animal">Dominate
Animal</a>:</td>
          <td> Subject animal obeys silent mental commands.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-fang">Magic
Fang, Greater</a>:</td>
          <td> One natural weapon of subject creature gets +1/four
levels on attack and damage rolls (max +5).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#meld-into-stone">Meld into
Stone</a>:</td>
          <td> You and your gear merge with stone.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#neutralize-poison">Neutralize
Poison</a>:</td>
          <td> Immunizes subject against poison, detoxifies venom in
or on subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#plant-growth">Plant Growth</a>:</td>
          <td> Grows vegetation, improves crops.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#poison">Poison</a>:</td>
          <td> Touch deals 1d10 Con damage, repeats in 1 min.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#quench">Quench</a>:</td>
          <td> Extinguishes nonmagical fires or one magic item.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-disease">Remove Disease</a>:</td>
          <td> Cures all diseases affecting subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleet-storm">Sleet Storm</a>:</td>
          <td> Hampers vision and movement.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#snare">Snare</a>:</td>
          <td> Creates a magic booby trap.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-plants">Speak with
Plants</a>:</td>
          <td> You can talk to normal plants and plant creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spike-growth">Spike Growth</a>:</td>
          <td> Creatures in area take 1d4 damage, may be slowed.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a>:</td>
          <td> Sculpts stone into any shape.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-iii">Summon
Nature&rsquo;s Ally III</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-breathing">Water
Breathing</a>:</td>
          <td> Subjects can breathe underwater.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-wall">Wind
Wall</a>:</td>
          <td className="last-row"> Deflects arrows, smaller creatures,
and gases.</td>
        </tr>
      </tbody>
    </table>
    <a id="fourth-level-druid-spells"></a><h5>4TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#air-walk">Air Walk</a>:</td>
          <td> Subject treads on air as if solid (climb at 45-degree
angle).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antiplant-shell">Antiplant
Shell</a>:</td>
          <td> Keeps animated plants at bay.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blight">Blight</a>:</td>
          <td> Withers one plant or deals 1d6/level damage to plant
creature.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#command-plants">Command Plants</a>:</td>
          <td> Sway the actions of one or more plant creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-water">Control Water</a>:</td>
          <td> Raises or lowers bodies of water.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure Serious
Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels spells and magical effects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-strike">Flame Strike</a>:</td>
          <td> Smite foes with divine fire (1d6/level damage).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom
of Movement</a>:</td>
          <td> Subject moves normally despite impediments.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#giant-vermin">Giant Vermin</a>:</td>
          <td> Turns centipedes, scorpions, or spiders into giant
vermin.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#ice-storm">Ice Storm</a>:</td>
          <td> Hail deals 5d6 damage in cylinder 40 ft. across.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reincarnate">Reincarnate</a>:</td>
          <td> Brings <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dead">
dead</a> subject back in a random
body.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-vermin">Repel Vermin</a>:</td>
          <td> Insects, spiders, and other vermin stay 10 ft. away.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#rusting-grasp">Rusting Grasp</a>:</td>
          <td> Your touch corrodes iron and alloys.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#scrying">Scrying</a><sup> F</sup>:</td>
          <td> Spies on subject from a distance.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spike-stones">Spike Stones</a>:</td>
          <td> Creatures in area take 1d8 damage, may be slowed.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-iv">Summon
Nature&rsquo;s Ally IV</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
      </tbody>
    </table>
    <a id="fifth-level-druid-spells"></a><h5>5TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-growth">Animal Growth</a>:</td>
          <td> One animal/two levels doubles in size.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#atonement">Atonement</a>:</td>
          <td> Removes burden of misdeeds from subject.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#awaken">Awaken <sup><sub>X</sub></sup></a>:</td>
          <td> Animal or tree gains human intellect.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#baleful-polymorph">Baleful
Polymorph</a>:</td>
          <td> Transforms subject into harmless animal.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning-storm">Call
Lightning Storm</a>:</td>
          <td> As call lightning, but 5d6 damage per bolt.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#commune-with-nature">Commune with
Nature</a>:</td>
          <td> Learn about terrain for 1 mile/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-winds">Control Winds</a>:</td>
          <td> Change wind direction and speed.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-critical-wounds">Cure
Critical Wounds</a>:</td>
          <td> Cures 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a>:</td>
          <td> Grants immunity to all death spells and negative
energy effects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hallow">Hallow</a><sup> M</sup>:</td>
          <td> Designates location as holy.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insect-plague">Insect Plague</a>:</td>
          <td> Locust swarms attack creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stoneskin">Stoneskin</a><sup> M</sup>:</td>
          <td> Ignore 10 points of damage per attack.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-v">Summon
Nature&rsquo;s Ally V</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transmute-mud-to-rock">Transmute
Mud to Rock</a>:</td>
          <td> Transforms two 10-ft. cubes per level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transmute-rock-to-mud">Transmute
Rock to Mud</a>:</td>
          <td> Transforms two 10-ft. cubes per level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#tree-stride">Tree Stride</a>:</td>
          <td> Step from one tree to another far away.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unhallow">Unhallow</a><sup> M</sup>:</td>
          <td> Designates location as unholy.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-fire">Wall of Fire</a>:</td>
          <td> Deals 2d4 fire damage out to 10 ft. and 1d4 out to 20
ft. Passing through wall deals 2d6 damage +1/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-thorns">Wall of Thorns</a>:</td>
          <td> Thorns damage anyone who tries to pass.</td>
        </tr>
      </tbody>
    </table>
    <a id="sixth-level-druid-spells"></a><h5>6TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antilife-shell">Antilife Shell</a>:</td>
          <td> 10-ft.-radius field hedges out living creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bears-endurance">Bear&rsquo;s
Endurance, Mass</a>:</td>
          <td> As bear&rsquo;s endurance, affects one subject/ level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#mass-bulls-strength">Bull&rsquo;s
Strength, Mass</a>:</td>
          <td> As bull&rsquo;s strength, affects one subject/level.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cats-grace">Cat&rsquo;s
Grace, Mass</a>:</td>
          <td> As cat&rsquo;s grace, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-light-wounds">Cure
Light Wounds, Mass</a>:</td>
          <td> Cures 1d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Dispel
Magic, Greater</a>:</td>
          <td> As dispel magic, but +20 on check.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-the-path">Find the Path</a>:</td>
          <td> Shows most direct way to a location.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-seeds">Fire Seeds</a>:</td>
          <td> Acorns and berries become grenades and bombs.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#ironwood">Ironwood</a>:</td>
          <td> Magic wood is strong as steel.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#liveoak">Liveoak</a>:</td>
          <td> Oak becomes treant guardian.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#move-earth">Move Earth</a>:</td>
          <td> Digs trenches and builds hills.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mass-owls-wisdom">Owl&rsquo;s
Wisdom, Mass</a>:</td>
          <td> As owl&rsquo;s wisdom, affects one subject/level.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-wood">Repel Wood</a>:</td>
          <td> Pushes away wooden objects.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spellstaff">Spellstaff</a>:</td>
          <td> Stores one spell in wooden quarterstaff.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-tell">Stone Tell</a>:</td>
          <td> Talk to natural or worked stone.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-vi">Summon
Nature&rsquo;s Ally VI</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transport-via-plants">Transport
via Plants</a>:</td>
          <td> Move instantly from one plant to another of the same
kind.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-stone">Wall
of Stone</a>:</td>
          <td className="last-row"> Creates a stone wall that can be
shaped.</td>
        </tr>
      </tbody>
    </table>
    <a id="seveth-level-druid-spells"></a><h5>7TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-plants">Animate Plants</a>:</td>
          <td> One or more plants animate and fight for you.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#changestaff">Changestaff</a>:</td>
          <td> Your staff becomes a treant on command.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control Weather</a>:</td>
          <td> Changes weather in local area.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#creeping-doom">Creeping Doom</a>:</td>
          <td> Swarms of centipedes attack at your command.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-moderate-wounds">Cure
Moderate Wounds, Mass</a>:</td>
          <td> Cures 2d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-storm">Fire Storm</a>:</td>
          <td> Deals 1d6/level fire damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heal">Heal</a>:</td>
          <td> Cures 10 points/level of damage, all diseases and
mental conditions.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#greater-scrying">Scrying, Greater</a>:</td>
          <td> As scrying, but faster and longer.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-vii">Summon
Nature&rsquo;s Ally VII</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunbeam">Sunbeam</a>:</td>
          <td> Beam <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> and deals 4d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#transmute-metal-to-wood">Transmute
Metal to Wood</a>:</td>
          <td> Metal within 40 ft. becomes wood.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-seeing">True Seeing</a><sup>
M</sup>:</td>
          <td> Lets you see all things as they really are.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-walk">Wind Walk</a>:</td>
          <td> You and your allies turn vaporous and travel fast.</td>
        </tr>
      </tbody>
    </table>
    <a id="eighht-level-druid-spells"></a><h5>8TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-shapes">Animal Shapes</a>:</td>
          <td> One ally/level polymorphs into chosen animal.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-plants">Control Plants</a>:</td>
          <td> Control actions of one or more plant creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-serious-wounds">Cure
Serious Wounds, Mass</a>:</td>
          <td> Cures 3d8 damage +1/level for many creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#earthquake">Earthquake</a>:</td>
          <td> Intense tremor shakes 5-ft./level radius.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#finger-of-death">Finger of
Death</a>:</td>
          <td> Kills one subject.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-metal-or-stone">Repel
Metal or Stone</a>:</td>
          <td> Pushes away metal and stone.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#reverse-gravity">Reverse
Gravity</a>:</td>
          <td> Objects and creatures fall upward.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-viii">Summon
Nature&rsquo;s Ally VIII</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunburst">Sunburst</a>:</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded"> Blinds</a> all within 10 ft., deals
6d6 damage.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whirlwind">Whirlwind</a>:</td>
          <td> Cyclone deals damage and can pick up creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#word-of-recall">Word of Recall</a>:</td>
          <td> Teleports you back to designated place.</td>
        </tr>
      </tbody>
    </table>
    <a id="ninth-level-druid-spells"></a><h5>9TH-LEVEL DRUID SPELLS</h5>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antipathy">Antipathy</a>:</td>
          <td> Object or location affected by spell repels certain
creatures.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-critical-wounds">Cure
Critical Wounds, Mass</a>:</td>
          <td> Cures 4d8 damage +1/level for many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#elemental-swarm">Elemental
Swarm</a>:</td>
          <td> Summons multiple elementals.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#foresight">Foresight</a>:</td>
          <td> &ldquo;Sixth sense&rdquo; warns of impending danger.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#regenerate">Regenerate</a>:</td>
          <td> Subject&rsquo;s severed limbs grow back, cures 4d8
damage
+1/level (max +35).</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shambler">Shambler</a>:</td>
          <td> Summons 1d4+2 shambling mounds to fight for you.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shapechange">Shapechange</a><sup>
F</sup>:</td>
          <td> Transforms you into any creature, and change forms
once per round.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of
Vengeance</a>:</td>
          <td> Storm rains acid, lightning, and hail.</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-ix">Summon
Nature&rsquo;s Ally IX</a>:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr>
          <td className="last-row"><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sympathy">Sympathy</a><sup>
M</sup>:</td>
          <td className="last-row"> Object or location attracts certain
creatures.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      